





































import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OMessageList: () => import('@/components/organisms/globalTools/message/o-message-list.vue')},

  setup(){
    const state = reactive({
      search: '',
      type: null
    })

    const templateTypes = [
      {
        name: 'Wszystkie',
        value: null
      },
      {
        name: 'Rejestracja',
        value: 'registration'
      },
      {
        name: 'Wiadomość po płatności',
        value: 'afterPayment'
      },
      {
        name: 'Wiadomości automatyczne',
        value: 'auto'
      },
      {
        name: 'Wiadomości po usunięciu',
        value: 'remove'
      },
      {
        name: 'Inne',
        value: 'other'
      }
    ]

    return { state, templateTypes }
  }
})
